import {
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
  List,
  Stack,
} from '@mui/material';
import {
  DriveFolderUpload as DriveFolderUploadIcon,
  Save,
  Settings,
  SettingsApplications,
} from '@mui/icons-material';
import { useState, useEffect, useCallback, useContext } from 'react';
import EditTabSourceModal from './EditTabSourceModal';
import { TagListSourceModal } from './TagListSourceModal';
import {
  emptyTag,
  updateDeviceParamsFormat,
  lookupNameToId,
  lookupIdToName,
} from '../helpers/Helpers';
import { getTagListById, saveTags, saveTagLayers } from '../helpers/APIHelpers';
import _ from 'lodash';
import './EditTabComponent.css';
import { useParams } from 'react-router-dom';
import { getTagsWithFilter } from '../helpers/APIHelpers';
import FeedbackModal from './FeedbackModal';
import SourceSelection from './SourceSelection';
import ConfirmDialog from './ConfirmDialog';
import TagDeployer from './DeployGrid';
import { SharedTagDialog } from './SharedTagDialog';
import { ConfigureTagList } from './ConfigureTagList';
import SelectCollisionMode from './SelectCollisionMode';
import TagGrid from './TagGrid';
import LoadXMLModal from './LoadXMLModal';
import { XMLParser } from 'fast-xml-parser';
import { LookupValueContext } from '../contexts/LookupValueContext';
import {
  insertElement,
  deleteElement,
  updateIndexList,
} from '../helpers/Utils';
import { ManageTagListsModal } from './ManageTagListsModal';

export default function EditTabComponent() {
  const [openSourceModal, setOpenSourceModal] = useState(false);
  const [openTagListModal, setOpenTagListModal] = useState(false);
  const [openManageTagListsModal, setOpenManageTagListsModal] = useState(false);
  const [openXMLModal, setOpenXMLModal] = useState(false);
  const [tags, setTags] = useState([]); // Original tag records, used to determine which fields have been edited, does not show in the grid
  const [editedTags, setEditedTags] = useState([]); // Tag records that are actually showing in the grid
  const [selectedTags, setSelectedTags] = useState([]); // Which rows are selected
  const [dirtyTags, setDirtyTags] = useState([]); // List of tag indexes that have been modified
  const [loading, setLoading] = useState(false);
  const [sourceFilter, setSourceFilter] = useState([]);
  const { assetIds } = useParams();
  const [infoMessages, setInfoMessages] = useState({
    type: 'snackbar',
    message: 'Loading...',
    severity: 'info',
  });
  const [targetAssets, setTargetAssets] = useState([]);
  const [newTagList, setNewTagList] = useState({}); // Information about new tag list to save grid into
  const [sourceTagLists, setSourceTagLists] = useState([]); // Tag lists to load tags from
  const [assetTargetFilter, setAssetTargetFilter] = useState([]); // Parameters to query for assets to target for deployment

  // Confirmation window
  const [showConfirmDeployDialog, setShowConfirmDeployDialog] = useState(false);
  const [confirmationOnClose, setConfirmationOnClose] = useState(null);
  const [confirmationTitle, setConfirmationTitle] = useState('Confirm');
  const [confirmationText, setConfirmationText] = useState('Confirm');

  const [showDeployGrid, setShowDeployGrid] = useState(false);
  const [tagsForDeploy, setTagsForDeploy] = useState([]);

  const [tagListConfigOpen, setTagListConfigOpen] = useState(false);

  const [distro, setDistro] = useState('3.2.3');

  const lookupValues = useContext(LookupValueContext);

  // Tag ID collision settings
  const [collisionMode, setCollisionMode] = useState(
    localStorage.getItem('collisionMode') || 'None Set',
  );

  const confirmationDialog = (
    <ConfirmDialog
      open={showConfirmDeployDialog}
      onClose={confirmationOnClose}
      title={confirmationTitle}
      content={confirmationText}
    />
  );

  const appendTagsToGrid = useCallback(
    (tagsAdded) => {
      let newEditedTags = [...editedTags];
      let newTags = [...tags];

      for (const tag of tagsAdded) {
        // Check for tagid collision
        const collisionIndex = editedTags.findIndex(
          (t) =>
            t.TagIdentifier &&
            tag.TagIdentifier &&
            t.TagIdentifier.toString() === tag.TagIdentifier.toString(),
        );
        if (collisionIndex !== -1) {
          // Handle collision
          if (collisionMode === 'Overwrite') {
            // Replace old tag with new tag
            newEditedTags.splice(collisionIndex, 1, tag);
            newTags.splice(collisionIndex, 1, tag);
          } else if (collisionMode === 'Ignore') {
            // Insert as normal
            insertElement(newEditedTags, tag, newEditedTags.length);
            insertElement(newTags, tag, newTags.length);
          }
        } else {
          // Insert as normal
          insertElement(newEditedTags, tag, newEditedTags.length);
          insertElement(newTags, tag, newTags.length);
        }
      }
      // Update state
      setEditedTags(newEditedTags);
      setTags(newTags);
    },
    [collisionMode, editedTags, tags],
  );

  const addNewEmptyTag = (at) => {
    const newEmptyTag = { ...emptyTag };
    let newEditedTags = [...editedTags];
    let newTags = [...tags];
    insertElement(newEditedTags, newEmptyTag, at);
    insertElement(newTags, newEmptyTag, at);
    setEditedTags(newEditedTags);
    setTags(newTags);
    setDirtyTags(updateIndexList(dirtyTags, at, -1));
  };

  const duplicateTag = (at) => {
    let dupe = { ...editedTags[at] };

    // Clear out fields that link the new duplicate to an existing tag or asset in the DB
    if (dupe.TagId) {
      delete dupe.TagId;
    }
    if (dupe.TCTCTagLayerId) {
      delete dupe.TCTCTagLayerId;
    }
    if (dupe.AssetId) {
      delete dupe.AssetId;
    }
    if (dupe.AssetNumber) {
      delete dupe.AssetNumber;
    }
    if (dupe.TagIdentifier) {
      delete dupe.TagIdentifier;
    }

    let newEditedTags = [...editedTags];
    let newTags = [...tags];
    insertElement(newEditedTags, dupe, at);
    insertElement(newTags, dupe, at);
    setEditedTags(newEditedTags);
    setTags(newTags);
    setDirtyTags(updateIndexList(dirtyTags, at, -1));
  };

  const deleteRow = (id) => {
    let newEditedTags = [...editedTags];
    let newTags = [...tags];
    deleteElement(newEditedTags, id);
    deleteElement(newTags, id);
    setEditedTags(newEditedTags);
    setTags(newTags);
    setDirtyTags(updateIndexList(dirtyTags, id, -1));
  };

  const getTags = useCallback(async () => {
    if (collisionMode === 'None Set') {
      collisionPrompt();
      return;
    }
    if (sourceFilter.length) {
      try {
        setLoading(true);
        let result = await getTagsWithFilter(sourceFilter);
        appendTagsToGrid(result.data);
      } catch (error) {
        const errNotice = {
          open: true,
          severity: 'error',
          message:
            'There was a problem with your query. Please adjust search parameters.',
        };
        if (error.message?.toLowerCase().includes('network')) {
          errNotice.message =
            'The application has lost connection to the internet.';
        }
        setInfoMessages(errNotice);
        console.error(error.toString());
      } finally {
        setLoading(false);
        setSourceFilter({});
      }
    }
  }, [sourceFilter, appendTagsToGrid, collisionMode]);

  useEffect(() => {
    const getTagsAsync = async () => {
      await getTags();
    };
    getTagsAsync();
  }, [getTags]);

  const [dialogTag, setDialogTag] = useState({});
  const [dialogId, setDialogId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const openTagDialog = (id) => {
    if (dialogOpen) return;
    setDialogTag(editedTags[id]);
    setDialogId(id);
    setDialogOpen(true);
  };

  useEffect(() => {
    if (assetIds) {
      const assetIdFilter = assetIds.split(',').map((item, idx, ogArr) => {
        return {
          rowId: idx,
          operator: 'AND',
          fieldName: 'AssetId',
          comparator: '=',
          value: item.trim(),
          addButton: ogArr.length - 1 === idx,
          fieldSource: 'Tag',
        };
      });
      setSourceFilter(assetIdFilter);
    }
  }, [assetIds]);

  useEffect(() => {
    let newTagsForDeploy = _.filter(editedTags, (_, index) =>
      selectedTags.includes(index),
    );
    setTagsForDeploy(newTagsForDeploy);
  }, [selectedTags, editedTags]);

  const collisionPrompt = () => {
    const closePrompt = async (_) => {
      setShowConfirmDeployDialog(false);
    };
    setConfirmationOnClose(() => closePrompt);
    setConfirmationTitle('Tag ID Collisions');
    setConfirmationText(
      <>
        {
          'You do not have a Tag ID Collision mode selected. Please select one before loading tags into the grid: '
        }
        <List>
          <li>
            <b>Ignore</b> - Tags will be appended to the grid with no action
            taken
          </li>
          <li>
            <b>Overwrite</b> - Tags with a tag ID collision will be overwritten
            with the loaded tag(s)
          </li>
          <li>
            <b>Skip</b> - Do not load tag IDs with a collision into the grid
          </li>
        </List>
      </>,
    );
    setShowConfirmDeployDialog(true);
  };

  const updateTagFromEditor = (row) => {
    let tagToUpdate = row.id;
    let newEditedTags = [...editedTags];
    newEditedTags[tagToUpdate] = row;
    setEditedTags(newEditedTags);

    let isDirty = dirtyTags.includes(tagToUpdate);
    if (!_.isEqual(row, tags[tagToUpdate])) {
      if (!isDirty) {
        let newDirtyTags = [...dirtyTags, tagToUpdate];
        setDirtyTags(newDirtyTags);
      }
    } else {
      if (isDirty) {
        let newDirtyTags = [...dirtyTags];
        _.remove(newDirtyTags, (index) => index === tagToUpdate);
        setDirtyTags(newDirtyTags);
      }
    }
    return row;
  };

  const getTagsToSave = () => {
    let tagsToSave = [];
    let selectedDirtyTags = _.filter(dirtyTags, (i) =>
      selectedTags.includes(i),
    );
    for (const tagIndex of selectedDirtyTags) {
      tagsToSave.push(editedTags[tagIndex]);
    }
    return tagsToSave;
  };

  const getTagLists = async () => {
    try {
      if (collisionMode === 'None Set') {
        collisionPrompt();
        return;
      }
      setLoading(true);
      let tagListTags = [];
      for (const tagListInfo of sourceTagLists) {
        const tagListRecord = await getTagListById(tagListInfo.TCTagListId);
        for (const tag of tagListRecord.data.Tags) {
          let modifiedTagLayer = {
            ...tag,
            TagListName: tagListInfo.Name,
          };
          if (
            modifiedTagLayer.SourceTypeId &&
            !modifiedTagLayer.SourceTypeName
          ) {
            modifiedTagLayer.SourceTypeName = lookupIdToName(
              modifiedTagLayer.SourceTypeId,
              'TagSourceType',
              lookupValues,
            );
          }
          tagListTags.push(modifiedTagLayer);
        }
      }
      appendTagsToGrid(tagListTags);
      const confirmNotice = {
        open: true,
        severity: 'success',
        message: 'Successfully appended tag list(s) to grid.',
      };
      setInfoMessages(confirmNotice);
    } catch (error) {
      const errNotice = {
        open: true,
        severity: 'error',
        message: 'An error occurred loading tag lists.',
      };
      if (error.message?.toLowerCase().includes('network')) {
        errNotice.message =
          'The application has lost connection to the internet.';
      }
      setInfoMessages(errNotice);
      console.error(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const constructNewTagListFromCurrentGrid = (newTagList) => {
    let tagsToSave = _.filter(editedTags, (_, index) =>
      selectedTags.includes(index),
    );
    let tagList = { ...newTagList };
    let Tags = [];
    for (const record of tagsToSave) {
      let tag = { ...record };
      if (tag.TagId) {
        delete tag.TagId;
      }
      if (tag.TCTCTagLayerId) {
        delete tag.TCTCTagLayerId;
      }
      if (tag.SourceTypeName && !tag.SourceTypeId) {
        tag.SourceTypeId = lookupNameToId(
          tag.SourceTypeName,
          'TagSourceType',
          lookupValues,
        );
      }
      Tags.push(tag);
    }
    return [tagList, Tags];
  };

  // Executes when the final save button is clicked after a save target has been selected
  const saveChanges = async () => {
    // Save tags & tag layers to the source records
    setInfoMessages({
      type: 'snackbar',
      severity: 'info',
      message: 'Tag changes have been queued for save.',
      open: true,
    });
    const tagsToSave = getTagsToSave();
    // Save AssetTag records
    try {
      const tagRecords = tagsToSave.filter((t) => t.AssetTagId);
      const res = await saveTags(tagRecords);
      const success = [];
      const fails = [];
      res.forEach((item) => {
        item.status === 'rejected' ? fails.push(item) : success.push(item);
      });

      const tagLayers = tagsToSave.filter((t) => t.TCTCTagLayerId);
      const tagLayerRes = await saveTagLayers(tagLayers);
      for (const item of tagLayerRes) {
        if (item.status === 'rejected') {
          fails.push(item);
        } else {
          success.push(item);
        }
      }
      if (fails.length > 0) {
        if (success.length === 0) {
          setInfoMessages({
            message: 'There was an error saving your changes. Please try again',
            severity: 'error',
            type: 'snackbar',
            open: true,
          });
        } else {
          let outStr = '';
          const tagsFailed = fails.map((item) => {
            const reason = item.reason;
            const dataObj = JSON.parse(reason?.config?.data);
            if (dataObj.TagId) {
              return {
                TagId: dataObj.TagId,
                reason: reason.code,
              };
            } else {
              return {
                TCTagLayerId: dataObj.TCTCTagLayerId,
                reason: reason.code,
              };
            }
          });
          tagsFailed.forEach((tag) => {
            if (tag.TagId) {
              outStr += `TagId: ${tag.TagId}, Reason: ${tag.reason} <br />`;
            } else {
              outStr += `TCTagLayerId: ${tag.TCTagLayerId}, Reason: ${tag.reason} <br />`;
            }
          });
          setInfoMessages({
            type: 'dialog',
            message: (
              <div>
                There was an error saving some of your changes. The following
                tags did not update properly:
                <br />
                {outStr}
              </div>
            ),
            title: 'Save Errors',
            open: true,
          });

          // leave the failed tags (and unchecked tags) as dirty and clean the rest
          const newDirtyTags = dirtyTags.filter(
            (tag, index) =>
              tagsFailed.includes(tag) || !selectedTags.includes(index),
          );
          setDirtyTags(newDirtyTags);
        }
      } else {
        let selectedTagChanges = [...editedTags];
        let newTags = [...tags];
        let remainingDirtyTags = [];
        for (const tagIndex of dirtyTags) {
          if (!selectedTags.includes(tagIndex)) {
            selectedTagChanges[tagIndex] = editedTags[tagIndex];
            remainingDirtyTags.push(tagIndex);
          } else {
            newTags[tagIndex] = editedTags[tagIndex];
          }
        }
        setEditedTags(selectedTagChanges);
        setTags(newTags);
        setDirtyTags(remainingDirtyTags || []);
        setInfoMessages({
          type: 'snackbar',
          message: 'Tags saved successfully.',
          severity: 'success',
          open: true,
        });
      }
    } catch (error) {
      setInfoMessages({
        type: 'snackbar',
        message:
          'There was an issue saving tags. Please try again or contact administrator.',
        severity: 'error',
        open: true,
      });
    }
  };

  const clearGrid = () => {
    setTags([]);
    setEditedTags([]);
    setDirtyTags([]);
    setSelectedTags([]);
  };

  const resetChanges = () => {
    setEditedTags(tags);
    setDirtyTags([]);
  };

  const convertXMLTagToCorrectFormat = (tag) => {
    let convertedTag =
      window.Enbase.Common.Tag.Normalization.ConvertTagFromHubInterfaceToDashboard(
        tag,
      );
    // Field mappings
    // Type -> TagType
    // TagID -> TagIdentifier
    // DisplayName -> TagName
    // DataType -> TagDataType
    convertedTag.TagTypeName = convertedTag.Type;
    convertedTag.TagIdentifier = convertedTag.TagID;
    convertedTag.TagName = convertedTag.DisplayName;
    convertedTag.DataTypeName = convertedTag.DataType;
    convertedTag.SourceTypeName = convertedTag.SourceType;
    convertedTag.IsActive =
      String(convertedTag.IsEnabled).toLowerCase() === 'true';
    convertedTag.AlarmTypeId = lookupNameToId(
      convertedTag.AlarmType,
      'TagAlarmType',
      lookupValues,
    );
    convertedTag.Alarm2TypeId = lookupNameToId(
      convertedTag.Alarm2Type,
      'TagAlarmType',
      lookupValues,
    );

    // Check DeviceParams format and adjust if needed
    convertedTag.DeviceParams = updateDeviceParamsFormat(
      convertedTag.DeviceParams,
      convertedTag.SourceTypeName,
      convertedTag.ChannelNumber,
    );
    delete convertedTag.Type;
    delete convertedTag.TagID;
    delete convertedTag.DisplayName;
    delete convertedTag.DataType;
    delete convertedTag.SourceType;
    delete convertedTag.IsEnabled;
    delete convertedTag.AlarmType;
    delete convertedTag.Alarm2Type;
    return convertedTag;
  };

  const convertXMLTagsToCorrectFormat = (tags) => {
    return tags.map((tag) => convertXMLTagToCorrectFormat(tag));
  };

  // Parse and load tags from XML
  const loadTagsFromXML = (xmlString) => {
    try {
      const parser = new XMLParser({
        ignoreAttributes: false,
        attributeNamePrefix: '',
      });
      const parsedXML = parser.parse(xmlString);
      if (!parsedXML.WriteTagConfigRequest) {
        throw new Error('Incorrect XML format');
      }
      if (_.isArray(parsedXML.WriteTagConfigRequest)) {
        const convertedTags = convertXMLTagsToCorrectFormat(
          parsedXML.WriteTagConfigRequest,
        );
        appendTagsToGrid(convertedTags);
      } else {
        const convertedTag = convertXMLTagToCorrectFormat(
          parsedXML.WriteTagConfigRequest,
        );
        appendTagsToGrid([convertedTag]);
      }
    } catch (e) {
      console.error(e);
      setInfoMessages({
        open: true,
        severity: 'error',
        message: 'Error parsing XML - incorrect format',
      });
    }
  };

  const dropHandler = (e) => {
    e.preventDefault();
    if (e.dataTransfer.items) {
      [...e.dataTransfer.items].forEach((dropItem, _) => {
        if (dropItem.kind === 'file') {
          dropItem
            .getAsFile()
            .text()
            .then((xml) => {
              loadTagsFromXML(xml);
            });
        }
      });
    }
  };

  const dragOverHandler = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Backdrop open={loading} sx={{ zIndex: 3 }}>
        <CircularProgress />
      </Backdrop>
      <FeedbackModal
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        type="snackbar"
        onClose={() => setInfoMessages({ ...infoMessages, open: false })}
        {...infoMessages}
        sx={{ zIndex: 3 }}
      />
      <Paper elevation={2} sx={{ p: 2, mt: 1 }}>
        <Typography gutterBottom variant="h5" component="div" color="primary">
          Tag Configurator
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="body1">
              Create, edit, and deploy tags from the tag configurator.
            </Typography>
          </Grid>
          <Grid container item xs={8} spacing={1} justifyContent={'flex-end'}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<DriveFolderUploadIcon />}
                onClick={() => setOpenTagListModal(true)}
              >
                Load Tag List
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SettingsApplications />}
                onClick={() => setOpenManageTagListsModal(true)}
              >
                Manage Tag Lists
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<DriveFolderUploadIcon />}
                onClick={() => setOpenSourceModal(true)}
              >
                Load Existing Tags
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<DriveFolderUploadIcon />}
                onClick={() => setOpenXMLModal(true)}
              >
                Load From XML
              </Button>
            </Grid>
          </Grid>
          <Grid container item justifyContent={'flex-end'}>
            <Grid item>
              <SelectCollisionMode
                collisionMode={collisionMode}
                setCollisionMode={setCollisionMode}
                showCollisionInfo={() => {
                  collisionPrompt();
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 2, my: 1, minHeight: 200 }}>
        {
          <>
            <div onDrop={dropHandler} onDragOver={dragOverHandler}>
              <TagGrid
                data={editedTags}
                setData={setEditedTags}
                deleteRow={deleteRow}
                duplicateTag={duplicateTag}
                openTagDialog={openTagDialog}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                tags={tags}
                dirtyTags={dirtyTags}
                setDirtyTags={setDirtyTags}
                addNewEmptyTag={addNewEmptyTag}
                clearGrid={clearGrid}
                resetChanges={resetChanges}
                distro={distro}
                setDistro={setDistro}
              />
            </div>
            <Divider sx={{ my: 2 }} />
            <Stack direction={'row'} spacing={2} justifyContent={'right'}>
              <Button
                aria-label="New Tag List"
                startIcon={<Settings />}
                onClick={() => setTagListConfigOpen(true)}
                variant="contained"
              >
                Save New Tag List
              </Button>
              <Button
                variant="contained"
                onClick={async () => {
                  await saveChanges();
                }}
                startIcon={<Save />}
              >
                Save Changes
              </Button>
              <ConfigureTagList
                open={tagListConfigOpen}
                onClose={() => {
                  setTagListConfigOpen(false);
                  setShowDeployGrid(false);
                }}
                tagListInfo={newTagList}
                setTagListInfo={setNewTagList}
                setInfoMessages={setInfoMessages}
                constructNewTagListFromCurrentGrid={
                  constructNewTagListFromCurrentGrid
                }
              />
            </Stack>
          </>
        }
      </Paper>
      {editedTags.length > 0 && (
        <SourceSelection
          setShowDeployGrid={setShowDeployGrid}
          setAssetTargetFilter={setAssetTargetFilter}
          assetTargetFilter={assetTargetFilter}
          setTargetAssets={setTargetAssets}
          setLoading={setLoading}
        ></SourceSelection>
      )}
      {showDeployGrid ? (
        <TagDeployer
          tags={tagsForDeploy}
          targets={targetAssets}
          setShowConfirmDeployDialog={setShowConfirmDeployDialog}
          setConfirmationOnClose={setConfirmationOnClose}
          setConfirmationTitle={setConfirmationTitle}
          setConfirmationText={setConfirmationText}
          setInfoMessages={setInfoMessages}
        />
      ) : (
        <></>
      )}
      <EditTabSourceModal
        open={openSourceModal}
        onClose={() => {
          setOpenSourceModal(false);
        }}
        setSourceFilter={setSourceFilter}
        sourceFilter={sourceFilter}
      />
      <LoadXMLModal
        open={openXMLModal}
        onClose={() => {
          setOpenXMLModal(false);
        }}
        loadXML={(xml) => {
          setLoading(true);
          loadTagsFromXML(xml);
          setLoading(false);
        }}
        setOpen={setOpenXMLModal}
      />
      <TagListSourceModal
        open={openTagListModal}
        onClose={() => setOpenTagListModal(false)}
        value={sourceTagLists}
        setValue={setSourceTagLists}
        load={getTagLists}
      />
      <ManageTagListsModal
        open={openManageTagListsModal}
        onClose={() => setOpenManageTagListsModal(false)}
        setInfoMessages={setInfoMessages}
      />
      {confirmationDialog}
      {dialogOpen && (
        <SharedTagDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          tag={dialogTag}
          setTag={setDialogTag}
          updateTag={updateTagFromEditor}
          setLoading={setLoading}
          setInfoMessages={setInfoMessages}
          dialogId={dialogId}
          distro={distro}
          existingTags={editedTags}
        />
      )}
    </>
  );
}
